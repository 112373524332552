.menu-item {
  display: flex;
  gap: 1rem;
  align-items: center;
  text-decoration: none;
  overflow: hidden;
  padding: 1rem;
  border-color: rgb(48, 110, 232);
  transition: all 0.2s ease-out;
  width: fit-content;
  white-space: nowrap;
}

.menu-item:hover {
  color: white;
  transition: all 0s;
}

.active {
  border-bottom: 1px solid rgb(48, 110, 232);
  /* box-shadow: 0px 0px 20px rgba(23, 92, 230, 0.7); */
  color: white;
}
