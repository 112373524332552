.history {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow: auto;
  width: 100%;
  padding: 0 1rem;
  padding-top: 1rem;
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.componentTitle {
  font-size: 1.8rem;
}

.btnClear {
  font-size: 1rem;
  padding: 0.25rem 0.75rem;
  margin-right: 15px;
}

.historyCard {
  position: relative;
  flex-shrink: 0;
}

.historyTrackInfo {
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.historyCardTitle {
  font-weight: 600;
}

.historyCardDate {
  font-size: 1rem;
  margin-top: 1rem;
}

.actions {
  place-self: flex-start;
  opacity: 0;
  transition: all 0.2s ease-in;
}

.historyCard:hover .streamLinks,
.historyCard:hover .actions {
  opacity: 1;
  transition: all 0s;
}

.actionIcon {
  display: block;
  font-size: 1.6rem;
  color: var(--color);
}

.actionIcon:hover {
  color: white;
  cursor: pointer;
}

.streamLinks {
  position: absolute;
  right: 5px;
  bottom: 5px;
  display: flex;
  align-items: center;
  gap: 5px;
  opacity: 0;
  transition: all 0.2s ease-in;
}

.streamLink {
  width: 24px;
}

@container (max-width: 301px) {
  .history {
    padding-left: 0;
  }
}
