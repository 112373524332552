.container {
  display: flex;
  gap: 2rem;
  height: 100%;
  width: 100%;
}

.content {
  container-type: inline-size;
  flex-grow: 1;
  overflow: auto;
  padding-right: 1rem;
}

.sidePanel {
  display: flex;
  width: 300px;
  flex-direction: column;
  container-type: inline-size;
}

@media screen and (max-width: 720px) {
  .container {
    display: block;
    position: relative;
    margin-top: 8rem;
  }

  .content {
    overflow: hidden;
    display: block;
    padding: 0 1rem;
  }

  .sidePanel {
    display: none;
    background-color: var(--background);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
  }

  .sidePanel.playerActive {
    display: block;
    width: 100%;
  }
}
