.pagination {
  display: flex;
  gap: 0.5rem;
}

.page-btn {
  flex-grow: 0;
  width: fit-content;
}

@container (max-width: 500px) {
  .pagination {
    width: 100%;
    justify-content: space-between;
  }
  .page-btn:not(:first-child) {
    display: none;
  }
  .page-btn:not(:last-child) {
    display: none;
  }
}
