.label {
  display: block;
}

.label:not(.disabled):hover {
  color: white;
  cursor: pointer;
}

.disabled {
  color: hsl(246, 6%, 45%);
  cursor: not-allowed;
}
