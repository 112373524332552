.card {
  position: relative;
  display: flex;
  align-items: center;
  gap: 1rem;
  /* width: 24rem; */
  width: 100%;
  height: 8rem;
  padding: 0.8rem;
  border: 1px solid rgb(24, 51, 103);
  background-color: rgb(15, 23, 42);
  border-radius: 0.5rem;
  opacity: 1;
  transition: all 0.2s ease-in;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  overflow: hidden;
}

.card > figure {
  width: 6.4rem;
  height: 6.4rem;
}

.card > figure > img {
  width: 6.4rem;
  height: 6.4rem;
  border-radius: 0.5rem;
  aspect-ratio: 1/1;
  object-fit: contain;
}

.card:not(.disabled):hover {
  cursor: pointer;
  transition: transform 0s ease-out;
  z-index: 10;
  color: white;
}

.card:active {
  transition: transform 0s ease-out;
}

.card.active {
  border-color: rgb(48, 110, 232);
  box-shadow: 0px 0px 2rem rgba(23, 92, 230, 0.7);
  color: white;
}

.card.loading {
  color: white;
  animation-duration: 0.8s;
  animation-name: loading;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-delay: 0.2s;
}

.card.error {
  color: white;
  animation-duration: 0.2s;
  animation-name: error;
  animation-iteration-count: 3;
}

.card.disabled {
  opacity: 0.5;
  transition: all 0.1s linear 0.3s;
}

@keyframes loading {
  from {
    box-shadow: 0px 0px 1rem rgba(23, 92, 230, 0.1);
    border-color: rgb(24, 51, 103);
  }
  to {
    box-shadow: 0px 0px 2rem rgba(23, 92, 230, 0.7);
    border-color: rgb(48, 110, 232);
  }
}

@keyframes error {
  from {
    box-shadow: 0px 0px 1rem rgba(23, 92, 230, 0.1);
    border-color: rgb(24, 51, 103);
  }
  to {
    box-shadow: 0px 0px 2rem rgba(23, 92, 230, 0.7);
    border-color: rgb(48, 110, 232);
  }
}

@media screen and (max-width: 800px) {
  .card {
    width: 100%;
  }
}
