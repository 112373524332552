@media screen and (max-width: 1000px) {
  .stationsMenu {
    font-size: 1.8rem;
  }
  .menuItemText {
    display: none;
  }
}

@media screen and (max-width: 720px) {
  .stationsMenu {
    flex-direction: column;
    gap: 1rem;
    font-size: 1.8rem;
  }
  .menuItemText {
    display: inline;
  }
}
