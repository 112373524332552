.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
}

.cardsList {
  overflow: auto;
  flex-grow: 1;
  place-content: flex-start;
}

.pagination {
  margin: 2rem auto;
}

.spinner {
  margin: 2rem auto;
}
