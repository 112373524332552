.spinner {
  width: 3rem;
  height: 3rem;
  border-top: 1px solid hsl(220, 80%, 55%);
  border-right: 1px solid hsl(220, 80%, 55%);
  box-shadow: 0px 0px 2rem rgba(23, 92, 230, 0.7), inset 0px 0px 1rem rgba(23, 92, 230, 0.7);
  border-radius: 100%;
  animation: 0.8s spin infinite linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
