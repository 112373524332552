.player {
  display: grid;
  grid-template-areas:
    'artwork'
    'track-info'
    'visualiser'
    'media-controls'
    'music-links';
  justify-items: center;
  gap: 2rem;
  background-color: rgb(15, 23, 42);
  border: 1px solid rgb(24, 51, 103);
  border-radius: 8px;
  padding: 20px 20px;
}

.media-controls {
  display: flex;
  grid-area: media-controls;
  align-self: end;
  justify-self: center;
}

.button-container {
  display: flex;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-top-color: rgba(0, 0, 0, 0.3);
  border-left-color: rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  padding: 1px;
}

.media-button {
  background-color: rgba(255, 255, 255, 0.05);
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 32px;
  color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-top-color: rgba(255, 255, 255, 0.15);
  transition: transform 0.2s ease-out;
}

.media-button:first-child {
  border-left-color: rgba(255, 255, 255, 0.15);
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.media-button:last-child {
  border-right-color: rgba(0, 0, 0, 0.3);
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.media-button:active {
  border: none;
  border-width: 1px;
  transform: scale(0.96);
}

.media-button.disabled {
  border: none;
  border-width: 1px;
  transform: scale(0.96);
  transition: all 0.2s ease-in;
}

.media-button-play {
  width: 80px;
}

.media-button-icon {
  opacity: 1;
  position: static;
  width: 12px;
  height: 12px;
  margin: 0;
  padding: 0;
  transition: opacity 0.2s ease-out;
}

.media-button.disabled .media-button-icon {
  opacity: 0.3;
  transition: opacity 0.2s ease-in;
}

.visualizer {
  width: 257px;
  grid-area: visualiser;
  align-self: center;
}

.artwork {
  display: block;
  width: 200px;
  height: 200px;
  border-radius: 14px;
  aspect-ratio: 1/1;
  object-fit: contain;
  grid-area: artwork;
}

.trackInfo {
  width: 100%;
  max-width: 257px;
  overflow: hidden;
  height: 50px;
  flex-shrink: 0;
  grid-area: track-info;
  align-self: start;
}

.stationName {
  font-size: 1.6rem;
  font-weight: 600;
}

.trackTitle {
  font-weight: 700;
  font-size: 1.8rem;
  color: white;
}

.musicLinks {
  display: flex;
  align-items: center;
  gap: 10px;
  height: 24px;
  grid-area: music-links;
}

.musicLinks img {
  width: 24px;
}

.active {
  border: 1px solid rgb(48, 110, 232);
  box-shadow: 0px 0px 2rem rgba(23, 92, 230, 0.7);
}

.loading {
  animation-duration: 0.8s;
  animation-name: loading;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-delay: 0.2s;
}

.error {
  animation-duration: 0.2s;
  animation-name: error;
  animation-iteration-count: 3;
}

@keyframes breeth {
  from {
    box-shadow: 0px 0px 1rem rgba(23, 92, 230, 0.1);
    border-color: rgb(24, 51, 103);
  }
  to {
    box-shadow: 0px 0px 2rem rgba(23, 92, 230, 0.7);
    border-color: rgb(48, 110, 232);
  }
}

@keyframes loading {
  from {
    box-shadow: 0px 0px 1rem rgba(23, 92, 230, 0.1);
    border-color: rgb(24, 51, 103);
  }
  to {
    box-shadow: 0px 0px 2rem rgba(23, 92, 230, 0.7);
    border-color: rgb(48, 110, 232);
  }
}

@keyframes error {
  from {
    box-shadow: 0px 0px 1rem rgba(23, 92, 230, 0.1);
    border-color: rgb(24, 51, 103);
  }
  to {
    box-shadow: 0px 0px 2rem rgba(23, 92, 230, 0.7);
    border-color: rgb(48, 110, 232);
  }
}

@container (min-width: 550px) {
  .player {
    justify-items: start;
    gap: 1rem;
    grid-template-areas:
      'artwork track-info'
      'artwork visualiser'
      'artwork media-controls'
      'music-links music-links';
    margin: 1rem auto;
    margin-top: 0;
    max-width: 510px;
  }
}

@media screen and (max-width: 549px) {
  .player {
    background: none;
    border: none;
    padding: 0;
  }

  .artwork {
    width: 257px;
    height: 257px;
  }

  .media-button {
    width: 70px;
    height: 40px;
  }

  .media-button-play {
    width: 100px;
  }

  .media-button-icon {
    width: 18px;
    height: 18px;
  }

  .active {
    border: none;
    box-shadow: none;
  }

  .loading {
    animation: none;
  }

  .error {
    animation: none;
  }
}
