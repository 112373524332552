.heroSection {
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 5rem;
  width: 90%;
  max-width: 1300px;
  margin: 5rem auto;
}

.heroImg {
  width: 60%;
  min-width: 300px;
  max-width: 600px;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}

.heroTitle {
  font-size: 5rem;
  font-weight: 600;
  letter-spacing: 0.2rem;
}

.heroText {
  font-size: 1.8rem;
}

.btnBrowse {
  font-size: 1.8rem;
  padding: 1rem 3rem;
  margin-top: 3rem;
  font-weight: 600;
}

.categoriesSection {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(5rem, 1fr));
  align-items: start;
  justify-items: center;
  gap: 1rem;
  max-width: 800px;
  margin: 0 auto;
  margin-top: 5rem;
}

.category {
  max-width: 9rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.categoryIcon {
  width: 100%;
  height: 100%;
  max-width: 5rem;
}

.categoryName {
  text-align: center;
}

.recommendedSection {
  display: flex;
  flex-direction: column;
  gap: 5rem;
  margin-top: 5rem;
}

.recommendedTitle {
  font-size: 2.4rem;
  font-weight: normal;
  margin-bottom: 1rem;
}

.playHistoryTitleContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.playHistoryTitleContainer .recommendedTitle {
  margin: 0;
}

.btnClear {
  font-size: 1rem;
  padding: 0.25rem 0.75rem;
  margin-right: 1rem;
}

@container (max-width: 680px) {
  .heroImg {
    display: none;
  }
  .categoriesSection {
    justify-content: space-between;
  }
}
