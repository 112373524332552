.cards-list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  justify-items: stretch;
  gap: 2rem;
  padding: 0;
  margin: 0 auto;
}

@container (max-width: 640px) {
  .cards-list {
    row-gap: 1rem;
  }
}
