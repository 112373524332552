@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul,
ol[role='list'] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.4;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  /* max-width: 100%; */
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

:root {
  --background: #13111c;
  --color: hsl(246, 6%, 65%);
  /* --background: black; */
  /* --background: rgb(15, 23, 42); */
}

html {
  font-family: Montserrat, sans-serif;
  font-size: 62.5%;
}

body {
  background-color: var(--background);
  color: hsl(246, 6%, 65%);
  font-size: 1.4rem;
  margin: 0 auto;
  padding: 2rem;
  height: 100vh;
}

#root {
  height: calc(99% - 6rem);
}

a {
  color: inherit;
  text-decoration: none;
  font-weight: 600;
}

a:hover {
  color: white;
}

h2 {
  font-size: 3rem;
  font-weight: 600;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: rgb(48, 110, 232) hsl(222, 47%, 18%);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background: hsl(222, 47%, 18%);
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(24, 51, 103);
  border-radius: 4px;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: rgb(48, 110, 232);
  border-radius: 4px;
}

@media screen and (max-width: 720px) {
  body {
    padding: 0;
  }
}
