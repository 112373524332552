.article {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.statusCode {
  font-size: 4.8rem;
  font-weight: 700;
}

.title {
  font-size: 3rem;
  font-weight: 600;
}
