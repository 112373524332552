.section {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.optionsContaienr {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 2rem;
}

.btnAdd {
  display: flex;
  gap: 1rem;
  padding: 0.25rem 1.4rem;
}

.cardsList {
  overflow: auto;
  flex-grow: 1;
  place-content: flex-start;
}

.spinner {
  margin: 2rem auto;
}

@container (max-width: 380px) {
  .optionsContaienr {
    flex-wrap: wrap-reverse;
  }
}
