.options {
  display: flex;
  gap: 2rem;
  margin-bottom: 2rem;
}

@media screen and (max-width: 340px) {
  .options {
    flex-wrap: wrap;
    justify-content: center;
  }
}
