.container {
  overflow: hidden;
  width: 100%;
}

.scrollable {
  display: block;
  white-space: nowrap;
  width: fit-content;
}

.scroll {
  animation-name: scroll;
  animation-iteration-count: 1;
  animation-timing-function: linear;
  animation-direction: normal;
}

@keyframes scroll {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-50%);
  }
}
