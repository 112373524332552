.signin-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  gap: 1rem;
  max-width: 40rem;
  margin: 2rem auto;
}

.form-title {
  text-align: center;
  margin-bottom: 5rem;
}

.signin-form {
  display: grid;
  grid-template-columns: max-content auto;
  align-items: center;
  column-gap: 1.4rem;
  row-gap: 0.5rem;
}

.validation-error {
  grid-column: 2/3;
}

.form-actions {
  grid-column: 1/3;
}

.form-actions button {
  width: 100%;
}

.signin-options {
  grid-column: 1/3;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 0.5rem;
}

.remember-group {
  display: flex;
  gap: 0.5em;
}

.errorMessage {
  place-self: center;
  color: indianred;
}

@container (max-width: 400px) {
  .signin-form {
    grid-template-columns: 1fr;
  }
  .validation-error {
    grid-column: 1/2;
  }
  .form-actions {
    grid-column: 1/2;
    margin-top: 2rem;
  }
  .signin-options {
    grid-column: 1/2;
    width: 100%;
  }
}
